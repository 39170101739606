<template>
  <div class="page-header-container">
    <div class="corporation-selector">
      <corporation-selector/>
    </div>

    <div>
      <user-info/>
    </div>
  </div>
</template>

<script>
import CorporationSelector from '@/components/CorporationSelector'
import UserInfo from '@/components/UserInfo'

export default {
  name: 'PageHeader',

  components: { CorporationSelector, UserInfo }
}
</script>

<style lang="less" scoped>
.page-header-container {
  color: white;
  display: flex;
  width: 100%;
  height: 100%;
}

.corporation-selector {
  flex: 1;
}
</style>