import router from '@/router'
import { constantRoutes } from '@/config/router'
import pinia from '@/store'
import { accountStore } from '@/store/account'
import { ls } from '@/utils/storage'

const store = accountStore(pinia)

function whiteList(path, routes) {
  if (!routes) {
    routes = constantRoutes
  }
  for (let i in routes) {
    if (routes[i].path === path) {
      return true
    } else if (routes[i].children) {
      return whiteList(path, routes[i].children)
    }
  }
  return false
}

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${to.meta.title} - 云九秒`
  }

  if (whiteList(to.path)) {
    next()
  } else {
    if (ls.get('Access-Token')) {
      if (store.authorization) {
        next()
      } else {
        store.GetAuth().then(() => {
          store.GetUserInfo().then(() => {
            next()
          }).catch(err => {
            console.log(err)
            store.Logout().finally(() => {
              next({ path: '/login' })
            })
          })
        }).catch(err => {
          console.log(err)
          store.Logout().finally(() => {
            next({ path: '/login' })
          })
        })
      }
    } else {
      next({ path: '/login' })
    }
  }
})