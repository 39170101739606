<template>
  <a-layout class="content-wrapper" v-scrollBar>
    <a-layout-header v-if="$slots.header" class="page-header-wrapper">
      <slot name="header"/>
    </a-layout-header>
    <a-layout-content class="content-container">
      <slot/>
    </a-layout-content>

    <page-footer/>
  </a-layout>
</template>

<script>
import PageFooter from '@/components/PageFooter'

export default {
  name: 'ContentLayout',

  components: { PageFooter }
}
</script>

<style lang="less" scoped>
.content-wrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .page-header-wrapper {
    padding: 0;
    background: white;
    height: auto;
  }

  .content-container {
    height: 100%;
    padding: 14px 14px 0 14px;
    flex: 1;
  }
}
</style>