<template>
  <a-menu :selected-keys="selected" mode="inline" class="header-dropdown-menu">
    <template v-if="!person">
      <a-menu-item :key="0" @click="handleMenuItemClick">
        <div class="root-wrapper">
          <div class="avatar" v-if="typeof info.avatar !== 'undefined'">
            <a-avatar
              :src="info.avatar ? `/api${info.avatar}` : null"
              :size="32"
              shape="square"
              :style="{ background: info.name && info.name.length > 0 ? getBackgroundColorByName(info.name) : null }"
            >
              {{ info.name && info.name.length > 0 ? info.name.substring(0, 1) : '' }}
            </a-avatar>
          </div>
          {{ info.name }}
        </div>
      </a-menu-item>

      <a-menu-divider/>

      <template v-for="(item, index) in list">
        <sub-menu
          v-if="item.children && item.children.length > 0"
          :menu-info="item"
          :selected="selected"
          :handle-sub-menu-click="handleSubMenuClick"
          :handle-menu-item-click="handleMenuItemClick"
        />

        <a-menu-item v-else :key="item.id" @click="handleMenuItemClick">
          <a-avatar
            :src="item.avatar ? `/api${item.avatar}` : null"
            shape="square"
            :style="{ background: getBackgroundColorByName(item.name) }"
          >
            {{ item.name.substring(0, 1) }}
          </a-avatar>
          {{ item.title }}
        </a-menu-item>

        <a-menu-divider v-if="index < list.length"/>
      </template>
    </template>

    <a-menu-item :key="-1" @click="handleMenuItemClick">
      创建/加入团队
    </a-menu-item>

    <a-menu-divider/>

    <a-menu-item :key="-2" @click="handleMenuItemClick">
      管理/退出团队
    </a-menu-item>
  </a-menu>
</template>

<script>
import { accountStore } from '@/store/account'
import { getBackgroundColorByName } from '@/utils/business'
import SubMenu from '@/components/CorporationSelector/SubMenu.vue'

export default {
  name: 'DropDownMenu',

  components: { SubMenu },

  model: {
    prop: 'modelValue',
    event: 'update'
  },

  props: {
    modelValue: { type: Boolean, default: () => false }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update', value)
      }
    },

    selected() {
      return [accountStore().selected]
    },

    info() {
      return accountStore().info
    },

    person() {
      return accountStore().companies.length === 0
    },

    list() {
      return accountStore().companies
    }
  },

  methods: {
    getBackgroundColorByName,

    handleMenuItemClick({ key }) {
      if (key === -1) {
      } else if (key === -2) {
      } else {
        accountStore().selected = key
      }
      this.visible = false
    },

    handleSubMenuClick(info) {
      if (!info.selectable) {
        return
      }
      accountStore().selected = info.id
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.header-dropdown-menu {
  :deep(li) {
    margin: 0 !important;

    .ant-menu-item {
      height: 40px;
      line-height: 40px;
    }
  }

  :deep(li.ant-menu-item-group) {
    padding-left: 14px;
  }

  :deep(.ant-menu-item-group-title) {
    cursor: pointer;
    padding: 0;
  }

  :deep(li.only-content) {
    margin: 0;

    span.ant-menu-title-content {
      margin-left: -10px;
    }
  }
}

.root-wrapper {
  display: flex;
  color: rgba(0, 0, 0, 0.85);
  height: 40px;
  line-height: 40px;
  padding: 0 16px 0 0;

  &:hover {
    color: #1890ff;
  }

  &:active {
    background: #e6f7ff;
  }

  .avatar {
    margin-right: 10px;

    :deep(.ant-avatar) {
      vertical-align: top;
      margin-top: 4px;
    }
  }
}
</style>