<template>
  <div class="dashboard-container">
    <a-card title="您创建的公司">
      <div v-if="company" class="corporation-content">
        <div class="corporation-info">
          <div>{{ company.name }}</div>
          <a-button @click="handleSelect(company.id)" type="primary">进入公司</a-button>
        </div>

        <div>
          <a-tooltip v-for="(item, index) in getDepartmentList(company.id)" :key="index" title="点击进入">
            <a-card-grid class="project-card-grid" @click="handleSelect(item.id)">
              <a-card :bordered="false" :body-style="{ padding: 0 }">
                <a-card-meta>
                  <template v-slot:title>
                    <div class="card-title">
                      <a-avatar
                        :src="item.avatar ? item.avatar : null"
                        shape="square"
                        :size="32"
                        :style="{ background: item.name && item.name.length > 0 ? getBackgroundColorByName(item.name) : null }">
                        {{ item.name.substring(0, 1) }}
                      </a-avatar>
                      <span>{{ item.name }}</span>
                    </div>
                  </template>
                </a-card-meta>
              </a-card>
            </a-card-grid>
          </a-tooltip>
        </div>
      </div>

      <div v-else>
        <a-button>创建公司</a-button>
      </div>
    </a-card>

    <a-card v-for="(item, index) in companies" :key="index" title="您参与管理的公司" class="corporation">
      <div class="corporation-content">
        <div class="corporation-info">
          <div>item.name</div>
          <a-button type="primary">进入公司</a-button>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { accountStore } from '@/store/account'
import { getBackgroundColorByName } from '@/utils/business'

export default {
  name: 'Dashboard',

  computed: {
    company() {
      if (accountStore().corps.findIndex(item => item.id === accountStore().hasCompany) > -1) {
        const company = accountStore().corps.find(item => item.id === accountStore().hasCompany)
        return {
          id: company.id,
          name: company.name,
          avatar: `/api${company.avatar}`
        }
      } else {
        return null
      }
    },

    companies() {
      return accountStore().companies.filter(item => item.id !== accountStore().hasCompany).map(item => ({
        id: item.id,
        name: item.name,
        avatar: `/api${item.avatar}`,
        selectable: item.selectable
      }))
    }
  },

  methods: {
    getBackgroundColorByName,

    getDepartmentList(parentId) {
      return accountStore().corps.filter(item => item.parentId === parentId).map(item => ({
        id: item.id,
        name: item.name,
        avatar: `/api${item.avatar}`
      }))
    },

    handleSelect(id) {
      accountStore().selected = id
    }
  }
}
</script>

<style lang="less" scoped>
.dashboard-container {
  padding: 14px 14px 0 14px;
}

.corporation {
  margin-top: 14px;
}

.corporation-content {
  display: flex;

  .corporation-info {
    width: 400px;
  }
}

.project-card-grid {
  width: 100%;
  cursor: pointer;
}
</style>