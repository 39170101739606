import { defineStore } from 'pinia'
import { loginByCode, loginByPassword } from '@/service/Account/login'
import { ls } from '@/utils/storage'
import router from '@/router'
import { auth } from '@/service/Account/auth'
import { userInfo } from '@/service/Account/user'
import md5 from 'md5'

export const accountStore = defineStore('accountStore', {
  state: () => ({
    authorization: false,
    companies: [],
    corps: [],
    hasCompany: 0,
    id: 0,
    info: {},
    selected: 0
  }),

  persist: {
    enabled: true,
    strategies: [{
      storage: localStorage,
      paths: ['companies', 'corps', 'id', 'selected']
    }]
  },

  actions: {
    Login(payload) {
      return new Promise((resolve, reject) => {
        if (payload.password) {
          loginByPassword({ ...payload, password: md5(payload.password) }).then(response => {
            ls.set({
              key: 'Access-Token',
              value: response.data
            })
            resolve()
          }).catch(err => {
            reject(err)
          })
        } else {
          loginByCode(payload).then(response => {
            ls.set({
              key: 'Access-Token',
              value: response.data
            })
            resolve()
          }).catch(err => {
            reject(err)
          })
        }
      })
    },

    Logout() {
      return new Promise(resolve => {
        this.authorization = false
        ls.remove('Access-Token')
        router.push({ path: '/login' })
        resolve()
      })
    },

    GetAuth() {
      return new Promise((resolve, reject) => {
        const _this = this

        function generator(companies, parent) {
          const list = companies.filter(item => _this.corps.findIndex(corp => item.id === corp.id) > -1)
          return companies.filter(item => _this.corps.findIndex(corp => item.id === corp.id) > -1).map(item => {
            if (item.children && item.children.length > 0) {
              item.children = generator(item.children, item)
            }
            return item
          })
        }

        auth().then(response => {
          this.corps = response.data.corps.filter(item => !item.roles || item.roles.findIndex(role => role.scope > 0) > -1)
          const companies = generator(response.data.companies)
          this.companies = companies.filter(item => item.children && item.children.length > 0)
          this.hasCompany = response.data.hasCompany
          this.id = response.data.id
          this.authorization = true
          if(!this.corps.map(item => item.id).includes(this.selected)){
            this.selected = 0
          }
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },

    GetUserInfo() {
      return new Promise((resolve, reject) => {
        userInfo().then(response => {
          this.info = response.data
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    }
  },

  getters: {
    current(state) {
      const selected = state.corps.find(item => item.id === state.selected)
      if (selected) {
        const current = { ...selected }
        if (selected.avatar) {
          current.avatar = `/api${selected.avatar}`
        }
        return current
      }
      return null
    },

    currentRole(state) {
      const selected = state.corps.find(item => item.id === state.selected)
      if (selected && selected.selected >= 0 && selected.roles && selected.roles.length > 0) {
        const currentRole = selected.roles.find(item => item.roleId === selected.selected)
        return {
          id: currentRole.roleId,
          name: currentRole.scopeName,
          scope: currentRole.scope,
          actions: currentRole.actions
        }
      }
      return null
    },

    //是否为售楼处
    isSales(state) {
      const selected = state.corps.find(item => item.id === state.selected)
      if (selected) {
        return selected.typeId === 4
      }
      return false
    }
  }
})