<template>
  <div class="userinfo-container">
    <div class="info-wrapper">
      <div class="logo-wrapper">
        <div class="logo">
          <a-avatar
            :size="40"
            :src="info.avatar ? `/api${info.avatar}` : null"
            :style="{ background: info.name && info.name.length > 0 ? getBackgroundColorByName(info.name) : 'none' }"
          >
            {{ info.name && info.name.length > 0 ? info.name.substring(0, 1) : '' }}
          </a-avatar>
        </div>
      </div>

      <div class="info-name">{{ info.name }}</div>
    </div>

    <div class="log-out">
      <a-icon @click="handleLogout" class="power-off" type="poweroff"/>
    </div>
  </div>
</template>

<script>
import { accountStore } from '@/store/account'
import { getBackgroundColorByName } from '@/utils/business'

export default {
  name: 'UserInfo',

  computed: {
    info() {
      return accountStore().info
    },

    role() {
      return accountStore().currentRole
    },

    roles() {
      if (accountStore().current.roles && accountStore().current.roles.length > 0) {
        return accountStore().current.roles.map(item => ({ id: item.roleId, name: item.scopeName }))
      }
      return []
    }
  },

  methods: {
    getBackgroundColorByName,

    handleSelect({ key }) {
      const current = accountStore().corps.find(item => item.id === accountStore().selected)
      if (current) {
        current.selected = key
        if (this.$route.meta.action && this.$route.meta.action.length > 0) {
          let matched = true
          for (const index in this.$route.meta.action) {
            const action = this.$route.meta.action[index]
            const actions = current.roles.find(item => item.roleId === key).actions
            if (actions && actions.length > 0) {
              matched = actions.findIndex(item => item.code === action) > -1
            } else {
              matched = false
            }
          }
          if (!matched) {
            this.$router.push('/')
          }
        }
      }
    },

    handleLogout() {
      accountStore().Logout()
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/font/font.css";

.userinfo-container {
  width: 100%;
  height: 100%;
  display: flex;
  line-height: 60px;

  .info-wrapper {
    display: flex;

    .logo-wrapper {
      height: 60px;
      display: flex;

      .logo {
        :deep(.ant-avatar) {
          vertical-align: top;
          margin-top: 10px;
          font-family: "PingFang Regular", Arial, sans-serif;
        }

        :deep(img) {
          height: auto;
        }
      }
    }

    .info-name {
      margin: 0 0 0 14px;
    }
  }

  .log-out {
    margin-left: 14px;

    .power-off {
      cursor: pointer;
    }
  }
}

.role-switcher {
  cursor: pointer;
  margin: 0 0 0 14px;
}
</style>