<template>
  <div>公司首页</div>
</template>

<script>
export default {
  name: 'Corporation'
}
</script>

<style scoped>

</style>