<template>
  <div class="header-warp">
    <a-dropdown v-model="visible" :trigger="['click']">
      <template v-if="person">
        <div class="logo-wrapper">
          <div class="logo">
            <a-avatar :src="c9s" :size="40" shape="square"/>
          </div>

          <div class="header-name">
            <h1 class="name">
              <span>雲九秒智慧营销管理平台</span>
              <span class="icon">
                <a-icon type="down"/>
              </span>
            </h1>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="logo-wrapper">
          <div class="logo">
            <a-avatar
              :src="current.avatar"
              :size="40"
              shape="square"
              :style="{ background: current.id ? getBackgroundColorByName(current.name) : 'none' }"
            >
              {{ current.name.substring(0, 1) }}
            </a-avatar>
          </div>

          <div class="header-name">
            <h1 class="name">
              <span>{{ current.name }}</span>
              <span class="icon">
                <a-icon type="down"/>
              </span>
            </h1>
          </div>
        </div>
      </template>

      <template v-slot:overlay>
        <drop-down-menu v-model="visible"/>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
import c9s from '@/assets/c9s.png'
import { accountStore } from '@/store/account'
import DropDownMenu from '@/components/CorporationSelector/DropDownMenu.vue'
import { getBackgroundColorByName } from '@/utils/business'

export default {
  name: 'CorporationSelector',

  components: { DropDownMenu },

  data() {
    return {
      c9s,
      visible: false
    }
  },
  computed: {
    person() {
      return accountStore().companies.length === 0
    },

    current() {
      return accountStore().current || { id: NaN, name: '雲九秒智慧营销管理平台', avatar: c9s }
    }
  },

  methods: { getBackgroundColorByName }
}
</script>

<style lang="less" scoped>
@import "@/font/font.css";

.header-warp {
  width: 100%;
  height: 100%;
  display: flex;
  line-height: 60px;

  .logo-wrapper {
    height: 60px;
    display: flex;
    cursor: pointer;

    .logo {
      :deep(.ant-avatar) {
        vertical-align: top;
        margin-top: 10px;
        font-family: "PingFang Regular", Arial, sans-serif;
      }

      :deep(img) {
        height: auto;
      }
    }

    .header-name {
      margin-left: 14px;

      .name {
        color: #fefeff;
        letter-spacing: 10px;
        font-weight: normal;
        font-family: "PingFang Regular", Arial, sans-serif;
        margin: 0;
        height: 40px;
        line-height: 60px;
        user-select: none;

        .icon {
          font-size: 0.6em;
        }
      }
    }
  }
}
</style>