<template>
  <a-menu :selected-keys="selected" mode="inline" class="sub-menu">
    <a-menu-item-group>
      <template v-slot:title>
        <div
          class="root-wrapper"
          :class="{ 'disabled': !menuInfo.selectable, 'selected': selected.includes(menuInfo.id) }"
          @click="handleSubMenuClick(menuInfo)"
        >
          <div class="avatar" v-if="typeof menuInfo.avatar !== 'undefined'">
            <a-avatar
              :size="32"
              shape="square"
              :src="menuInfo.avatar ? `/api${menuInfo.avatar}` : null"
              :style="{ background: getBackgroundColorByName(menuInfo.name) }"
            >
              {{ menuInfo.name && menuInfo.name.length > 0 ? menuInfo.name.substring(0, 1) : '' }}
            </a-avatar>
          </div>
          {{ menuInfo.name }}
        </div>
      </template>

      <template v-if="menuInfo.children" v-for="item in menuInfo.children">
        <template v-if="item.children && item.children.length > 0">
          <sub-menu
            :selected="selected"
            :menu-info="item"
            :handle-sub-menu-click="handleSubMenuClick"
            :handle-menu-item-click="handleMenuItemClick"
          />
        </template>

        <template v-else>
          <a-menu-item :key="item.id" @click="handleMenuItemClick" class="only-content">
            {{ item.name }}
          </a-menu-item>
        </template>
      </template>
    </a-menu-item-group>
  </a-menu>
</template>

<script>
import { getBackgroundColorByName } from '@/utils/business'

export default {
  name: 'SubMenu',

  props: {
    menuInfo: { type: Object, default: () => ({ id: NaN, name: '' }) },
    selected: { type: Array },
    handleSubMenuClick: { type: Function },
    handleMenuItemClick: { type: Function }
  },

  methods: { getBackgroundColorByName }
}
</script>

<style lang="less" scoped>
.sub-menu {
  :deep(.ant-menu-item-group) {
    padding-left: 24px !important;
  }

  :deep(.ant-menu-item-group-title) {
    padding: 0;
  }
}

.root-wrapper {
  display: flex;
  color: rgba(0, 0, 0, 0.85);
  height: 40px;
  line-height: 40px;
  padding: 0 16px 0 0;

  &:hover {
    color: #1890ff;
  }

  &:active {
    background: #e6f7ff;
  }

  .avatar {
    margin-right: 10px;

    :deep(.ant-avatar) {
      vertical-align: top;
      margin-top: 4px;
    }
  }
}

.disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: default;
}

.selected {
  background: #e6f7ff;
  border-right: 3px solid #1890ff;
}
</style>