import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import Vue from 'vue'

function scrollBar(el) {
  if (el._ps_ instanceof PerfectScrollbar) {
    el._ps_.update()
  } else {
    //el上挂一份属性
    el._ps_ = new PerfectScrollbar(el, {
      // 要配什么属性自己看官网，此处不会解释任何其配置项的含义
      suppressScrollX: true
    })
  }
}

Vue.directive('scrollBar', {
  inserted(el, binding, vNode) {
    const rules = ['fixed', 'absolute', 'relative', 'sticky']
    if (!rules.includes(window.getComputedStyle(el, null).position)) {
      console.error(`perfect-scrollbar所在的容器的position属性必须是以下之一：${rules.join('、')}`)
    }
    scrollBar(el)
  },

  componentUpdated(el, binding, vNode) {
    vNode.context.$nextTick(
      () => {
        scrollBar(el)
      }
    )
  }
})
